<template>
  <PageSection>
    <div class="grid grid-cols-1 items-center gap-x-5 gap-y-2 md:grid-cols-2">
      <BaseSelectSimpleAjax
        :key="cParent"
        v-model="city.parent_id"
        label-class="text-sm text-gray-500 capitalize"
        :url="'city-for-select'"
        :url-params="{
          with_not_parent: true
        }"
        :label="$t('attributes.parent_id')"
        name="parent_id"
        attribute-label="full_name"
        :default-ajax="cParent"
        :errors="errors.parent_id"
        :disabled="$route.params?.parent_id && !!city?.parent_id"
      />
      <BaseShowEditTranslation
        v-model:translations="city.name"
        :label="$t('attributes.name')"
        name="name"
        :edition-mode="true"
        :required="true"
      />
      <SelectCountries
        v-model="city.country"
        :edition-mode="true"
        :errors="errors.country"
        :disabled="$route.params?.parent_id && !!city?.country"
      />
      <BaseShowEditInput
        v-model="city.zip"
        :label="$t('attributes.zip')"
        :edition-mode="true"
        name="zip"
        :required="true"
        :errors="errors.zip"
      />
      <!--      <BaseShowEditSelectSimple-->
      <!--          v-model="city.region_id"-->
      <!--          :label="$t('attributes.region_id')"-->
      <!--          name="name"-->
      <!--          :edition-mode="true"-->
      <!--          :options="regions"-->
      <!--          :required="true"-->
      <!--          :errors="errors.region_id"-->
      <!--      />-->
      <BaseShowEditInput
        v-model="city.latitude"
        :label="$t('attributes.latitude')"
        :edition-mode="true"
        name="latitude"
        :errors="errors.latitude"
        :required="true"
      />
      <BaseShowEditInput
        v-model="city.longitude"
        :label="$t('attributes.longitude')"
        :edition-mode="true"
        name="longitude"
        :errors="errors.longitude"
        :required="true"
      />
      <BaseShowEditInput
        v-model="city.web"
        :label="$t('attributes.web')"
        :edition-mode="true"
        name="web"
        :errors="errors.web"
      />
      <BaseShowEditInput
        v-model="city.enaos_id"
        :label="$t('attributes.enaos_id')"
        :edition-mode="true"
        name="enaos_id"
        :errors="errors.enaos_id"
      />
    </div>
  </PageSection>
</template>

<script>
import PageSection from '@c/PageSection.vue'
import BaseWikiCityMixin from '@u/mixins/wiki/BaseWikiCityMixin'
import BaseShowEditTranslation from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTranslation.vue'
import SelectCountries from '@c/InitialStateFormComponent/Enums/SelectCountries.vue'
import BaseSelectSimpleAjax from '@c/addf-package/components/BaseSelect/BaseSelectFormSimple/BaseSelectSimpleAjax.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'

export default {
  name: 'WikiCityInformation',
  components: {
    BaseShowEditInput,
    BaseSelectSimpleAjax,
    SelectCountries,
    BaseShowEditTranslation,
    PageSection
  },
  mixins: [BaseWikiCityMixin],
  computed: {
    regions() {
      return this.initialMetadata?.models?.wiki_regions.map((region) => {
        return {
          id: region.id,
          name: region.name[this.$i18n.locale]
        }
      })
    },
    cParent() {
      if (!this.city?.parent?.id || !this.city?.parent_id) {
        return {}
      }

      return {
        id: this.city.parent.id,
        full_name: `${this.city.parent.zip} ${
          this.city.parent.name[
            this.initialMetadata?.settings?.default_language
          ]
        }`
      }
    }
  }
}
</script>
