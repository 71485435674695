<template>
  <div class="flex flex-row gap-2">
    <BaseButton
      color="dark"
      icon="long-arrow-left"
      :title="$t('button.back')"
      :disabled="disableActions"
      @click="$router.go(-1)"
    />
    <BaseButton
      v-if="viewModel"
      icon="eye"
      :title="$t('button.go_to_dossier')"
      :size="'small'"
      :disabled="disableActions"
      @click="redirectToModel"
    />
    <BaseButton
      v-if="modelNotification.state !== 7"
      color="secondary"
      icon="file-lines"
      :title="$t('button.save_to_draft')"
      :size="'small'"
      :disabled="disableActions"
      @click="saveNewOrExistingEmailToDraft"
    />
    <BaseButton
      icon="paper-plane"
      :title="$t('button.send')"
      :size="'small'"
      :disabled="disableActions"
      :display-loader="disableActions"
      @click="sendNewEmail"
    />
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import {mapActions, mapGetters} from 'vuex'
import mailboxTypes from '@/assets/enums/notifications/mailboxTypes'
import MailboxTypes from "@/assets/enums/notifications/mailboxTypes";
// import MailboxTypes from '@/assets/enums/notifications/mailboxTypes'

export default defineComponent({
  name: 'MailBoxCTAComponent',
  components: {BaseButton},
  props: {
    modelNotification: {
      type: Object,
      required: true
    },
  },
  emits: [
    'update:modelNotification',
    'on:disable:fields',
  ],
  data() {
    return {
      disableActions: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth'
    }),
    localModelNotification: {
      get() {
        return this.modelNotification
      },
      set(value) {
        this.$emit('update:modelNotification', value)
      }
    },
    viewModel() {
      return this.modelNotification?.model_type !== null
    },
  },
  methods: {
    ...mapActions({
      processAddOrUpdateModelNotificationAction:
        'modelNotification/addOrUpdateModelNotification'
    }),
    redirectToModel() {
      if (this.modelNotification.model_type === 'App\\Models\\Dossier' && this.viewModel) {
        this.$router.push({
          name: 'dossier_form_edit_route',
          params: {
            id: this.modelNotification.model_id
          }
        })
      }
    },
    saveNewOrExistingEmailToDraft() {
      this.modelNotification.state = 7
      this.modelNotification.creator_id = this.auth.id
      this.modelNotification.priority = 'false'

      if (this.modelNotification.id) {
        this.processAddOrUpdateModelNotificationAction(
          this.modelNotification
        ).then((result) => {
          this.$h.toastSuccess(
            this.$t('toasts.message_has_been_saved_to_draft')
          )
          this.$router.push({
            name: 'mail_box_page_group_id',
            params: {
              path: mailboxTypes.DRAFT,
              groupId: result.model_notification.group_id
            }
          })
        })
      } else {
        this.processAddOrUpdateModelNotificationAction(
          this.modelNotification
        ).then((result) => {
          this.$h.toastSuccess(
            this.$t('toasts.message_has_been_saved_to_draft')
          )
          this.$router.push({
            name: 'mail_box_page_group_id',
            params: {
              path: mailboxTypes.DRAFT,
              groupId: result.model_notification.group_id
            }
          })
        })
      }
    },

    sendNewEmail() {
      this.disableActions = true
      this.$emit('on:disable:fields', true)

      this.modelNotification.state = 0
      this.modelNotification.sender_id = this.auth.id
      this.modelNotification.sender = this.auth.full_name
      this.modelNotification.priority = 'false'

      if (!this.modelNotification.attachments) {
        this.modelNotification.attachments = []
      }

      this.processAddOrUpdateModelNotificationAction(
        this.modelNotification
      ).then((result) => {
        this.$h.toastSuccess(this.$t('toasts.email_sent'))

        if (this.$route.name !== 'mail_box_page_group_id') {
          this.$router.push({
            name: 'mail_box_page_group_id',
            params: {
              path: MailboxTypes.SENT,
              groupId: result.model_notification.group_id
            }
          })
        } else {
          this.$emit('update:modelNotification', result.model_notification)
        }

        this.disableActions = false
        this.$emit('on:disable:fields', false)
      })
    }
  }
})
</script>
